$color_principal_light: #fff;
$color_secondary_light: #F1EDEA;
$color_principal_font_light: #000000;
$color_secondary_font_light: #565554;
$color_terciary_font_light: #000000;
// txema app colors #191919, #1b0d0a, #ffffff, #8e9da8, #666d66, #f68712
$color_principal_dark: #191919;
$color_secondary_dark: #282828;
$color_principal_font_dark: #fff;
$color_secondary_font_dark: #b8b2af;
$color_terciary_font_dark: #716e6c;

$accent_color: #f63012;

body {
    header.second {
        background-color: var(--colorPrincipalLight, $color_principal_light);

        .navbar-nav > .nav-item > .nav-link {
            color: $color_principal_font_light;
            cursor: pointer;

            &:hover {
                color: var(--accentColor, $accent_color);
            }
        }
    }
    .blog-icons {
        i {
            color: $color_principal_font_light;
            &:hover {
                color: var(--accentColor, $accent_color);
            }
        }
    }

    .content-blog-icons {
        i {
            color: $accent_color !important;
        }
    }

    blockquote {
        border-color: $accent_color !important;

        span {
            color: $accent_color !important;
        }
    }

    p {
        span {
            color: $accent_color !important;
        }
    }

    .blog-space {
        background-color: var(--colorPrincipalLight, $color_principal_light);

        span {
            color: $color_secondary_font_light;
        }

        h2 {
            color: $color_principal_font_light;
        }

        .blog-modern:hover {
            .blog-category {
                color: $color_principal_font_light;
            }
        }

        .blog-details {
            background-color: var(--colorSecondaryLight, $color_secondary_light);

            h5 {
                color: $color_principal_font_light;
            }

            h6 {
                color: $color_secondary_font_light;
            }

            span {
                color: var(--accentColor, $accent_color);
            }
        }
    }

    ul.social-icon.white {
        background-color: var(--colorPrincipalLight, $color_principal_light);
        a:hover {
            color: var(--accentColor, $accent_color);
        }
    }

    ul.social-icon.black {
        background-color: $color_principal_dark;
        a:hover {
            color: var(--accentColor, $accent_color);
        }
    }
    
    
}

.blogpostlayout-page-02 {
    &.swiper-navigation-03 {

        .swiper-button-prev,
        .swiper-button-next {
            background: #fff;
            transform: translateY(-50%);
        }

        .swiper-button-prev {
            left: 40px;

            @screen sm {
                left: 25px
            }

            @screen xs {
                left: -5px
            }
        }

        .swiper-button-next {
            right: 40px;

            @screen sm {
                right: 25px
            }

            @screen xs {
                right: -5px
            }
        }
    }
}