$color_principal_light: #191919;
$color_secondary_light: #fffaea;
$color_principal_light_trans: #33301f1c;
$color_secondary_light_trans: #FF2d1813;
$color_principal_font_light: #fff;
$color_secondary_font_light: #b8b2af;
$color_terciary_font_light: #716e6c;
$color_principal_font_dark: #000000;
$color_secondary_font_dark: #232323;
$color_terciary_font_dark: #32302f;

$accent_color: #f63012;

body {

    .colaborator-init {
        margin: 0px !important;
        .colaborator-image {
            .colaborator {
                margin-top: 20px;
                margin-bottom: 20px;
                max-width: 200px;
                background-size: contain;
                background-repeat: no-repeat;
            }
            margin-top: 20px;
            text-align: -webkit-center;
        }
    } 
    .colaborator-row {
        border-width: 1px;
        border-color: $color_terciary_font_light;
    }   

    background-color: $color_principal_light;

    .dark-mode {
        .rounded-full {
            background-color: $color_principal_light;

            i {
                color: $color_terciary_font_light;
            }
        }

    }

    .CookieConsent {
        a {
            margin-left: 1rem;
            color: $color_secondary_font_light;
        }

        a:hover {
            color: $accent_color;
        }
    }

    .scroll-top-arrow {
        .rounded-full {
            background-color: $accent_color;

            i {
                color: $color_principal_light;
            }
        }

    }

    header {

        .navbar-dark .navbar-nav>.nav-item>.nav-link:hover {
            color: $accent_color;
            cursor: pointer;
            opacity: 1;
        }

        .social-icon.xs li {
            color: $color_principal_font_light;
        }

        a:hover {
            color: $accent_color;
        }

        .navbar-togle-line {
            background-color: white !important;
        }
    }

    header.header-appear {
        .navbar-dark .navbar-nav>.nav-item>.nav-link {
            color: $color_principal_font_light;
            cursor: default;

            &:hover {
                color: $accent_color;
                cursor: default;
            }
        }

        .social-icon.xs li {
            color: $color_principal_font_light;


        }

    }

    header .navbar-dark .navbar-nav>.nav-item.active>.nav-link {
        color: $accent_color;
        cursor: default !important;
    }

    .header-appear {
        background-color: $color_principal_light !important;
    }

    &.reverse-scroll {
        &.sticky-header {
            &.header-appear {
                .menu-light {
                    >.header-transparent {
                        .navbar-nav {
                            >.nav-item {
                                >.nav-link {
                                    color: $color_secondary_font_light !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    // Sección slider principal
    .main-slider {
        .bg-description {
            background-color: transparent;

            .number {
                color: $accent_color;
                text-shadow: 0px 0px 2px black;
            }

            .separator {
                color: $color_secondary_light;
                text-shadow: 0px 0px 2px black;
            }

            .text {
                color: $color_secondary_font_light;
                text-shadow: 0px 0px 2px black;
            }

            h2 {
                color: $color_principal_font_light;
                text-shadow: 1px 1px 10px black;
            }

        }
    }

    // Sección sobre nosotros
    .about-section {

        h1,
        div {
            color: $color_principal_font_light;
        }

        p {
            color: $color_secondary_font_light;
        }

        a {
            color: $color_secondary_font_light;
        }

        a:hover {
            color: $accent_color;
        }
    }

    // Seccion servicios
    .service-section {
        background-color: $color_secondary_light;

        .service-image {
            background-size: cover;
            height: 460px;

            h2 {
                padding-left: 15px;
                color: $accent_color !important;
            }

            .feature-box-content {
                padding: 15px 80px 15px 15px;
                span {
                    color: $color_principal_font_dark;
                }
    
                .paragraph {
                    color: $color_principal_font_dark;
                }
            }

        }

        .btn-slider-next:hover {
            color: $accent_color;
        }

        .btn-slider-prev:hover {
            color: $accent_color;
        }

        .overlap {
            color: $color_principal_light;
            --overlapheight: -50px !important;
        }

        h2 {
            color: $color_principal_font_dark;
        }

        span {
            color: $color_secondary_font_dark;
        }

        .service {
            border-color: $color_secondary_font_light;

            transition: var(--default-transition);
            background-color: rgba(255, 255, 255, 0.6);
            height: 270px;

            .heading-4 {
                color: $accent_color;
            }


            &:hover {
                background-color: rgba(255, 255, 255, 0.4);
                border-color: $accent_color !important;
                box-shadow: 0 0 15px rgb(255 255 255 / 8%);

                .move-bottom-top {
                    opacity: 1;
                    transform: translateY(0);
                }
                transform: translateY(-10px);
                

            }

            .move-bottom-top {
                opacity: 0;
                transform: translateY(20px);
                transition: var(--default-transition);
            }

            .feature-box-move-bottom-top {
                transition: var(--default-transition);
            }
        }

    }

    .recomendation {
        padding-top: 50px !important;
        padding-bottom: 150px !important;

        @media (max-width: 991px) {
            padding-top: 150px !important;
        }

        .font {
            .line {
                background-color: white;
            }
        }
        span {

            color: $color_secondary_font_light;
        }

        h2 {
            color: $color_principal_font_light;
        }
    }

    .recomendation-bis {
        padding-top: 60px !important;
        padding-bottom: 180px !important;
        background-color: $color_secondary_light;

        .display-none {
            opacity: 0;
        }

        .recomendation-text {
            margin-top: 50px;
            @screen md {
                margin-bottom: 50px;
            }
        }

        .relative {

            .img-bis {
                width: 480px;
                height: 554px;
            }

            @media (max-width: 991px) {
                margin-top: 90px !important;
            }
        }

        span {
            color: $color_secondary_font_dark;
        }

        h2 {
            color: $color_principal_font_dark;
        }
    }

    .portfolio {
        .subtitle {
            color: $color_secondary_font_light;
        }

        h2 {
            color: $color_principal_font_light;
        }

        .filter-menu {
            span {
                color: white;
            }

            span:hover {
                color: $accent_color;
            }
        }

        .filter-menu .active {
            span {
                color: $accent_color !important;
            }

        }

        .social {

            text-align: center;
            .icon-social {
                border-color: white !important;
                border-style: solid !important;
                border-radius: 100% !important;
                border-width: 3px !important;

                .brand-icon {
                    color: white;
                }
            }

            .icon-social:hover {
                border-color: $accent_color !important;

                .brand-icon {
                    color: $accent_color;
                }
            }

        }
    }

    .blog-space {
        background-color: $color_principal_light;

        span {
            color: $color_secondary_font_light;
        }

        h2 {
            color: $color_principal_font_light;
        }

        .blog-details {
            background-color: $color_secondary_light;

            h5 {
                color: $color_principal_font_light;
            }

            h6 {
                color: $color_secondary_font_light;
            }

            span {
                color: $accent_color;
            }
        }
    }

    .contact {
        background-color: $color_secondary_light;

        span {
            color: $color_secondary_font_light;
        }

        h2 {
            color: $color_principal_font_light;
        }

        .contact-box {
            background-color: $color_principal_light;

            .contact-form {

                button {
                    background-color: $accent_color;
                    color: white;
                    border-color: $accent_color;
                }

                button:hover {
                    background-color: $color_principal_light;
                    color: $accent_color;
                    border-color: $accent_color;
                    border-style: solid;
                    border-width: 2px;
                }

                .check {
                    margin-top: 5px;
                }
            }

            .politics {
                span {
                    color: $color_secondary_font_light;
                }

                .underline {
                    color: $color_principal_font_light;
                }

                .underline:hover {
                    color: $accent_color;
                }
            }
        }

        .input {
            background-color: $color_principal_light;
            color: $color_secondary_font_light;
        }
    }

    .newsletter {

        span {
            color: $color_principal_font_light;
        }

        h6 {
            color: $color_principal_font_light;
        }

        .input {
            background-color: var(--colorSecondaryLight, $color_secondary_light);
            color: $color_secondary_font_light;
            border-color: $color_secondary_font_light;
        }

        .icon {
            color: $color_secondary_font_light;

        }

        .button-news {
            background-color: var(--colorPrincipalLight, $color_principal_light);
        }
    }

    .footer {
        background-color: $color_principal_light;

        .contact-links:hover {
            color: $accent_color;
        }

        .map {
            @media (max-width: 991px) {
                height: 180px;
                width: 350px;
            }
        }

        @media (max-width: 991px) {
            p {
                font-size: 1.1rem;
            }
        }
    }

    main {
        margin-top: 80px !important;
    }

    .privacy {

        .second {
            background-color: white !important;
        }

        .title-section {
            background-color: $color_secondary_light;
            border-bottom-color: $color_principal_light;
            border-bottom-style: solid;
            border-bottom-width: 1px;
        }

        ul.social-icon.black {
            background-color: $color_principal_light !important;

            a:hover {
                color: $accent_color;
            }
        }

        .privacy-doc {
            background-color: $color_secondary_light;
        }

    }

}

.fancy-text-box-05 .fancy-box-item:hover .bg-banner-image {
    background-color: var(--accentColor, $accent_color);
    opacity: 0.6 !important;
    background-image: none !important;
}

.interior-design-swiper {

    .swiper-pagination-bullets {
        .swiper-pagination-bullet {
            height: 12px;
            width: 12px;
            background: #fff;
            border-color: #fff;
            opacity: .5;
        }
    }
}

.interiordesign-counter {
    .vertical-counter {
        span {
            font-size: 40px !important;
        }
    }

    .counter-content {
        margin-top: 0;

        .counter-title {
            font-weight: 400;
        }
    }
}

.interiordesign-header-menu {
    .navbar-nav {
        >.nav-item {
            >.nav-link {
                text-transform: uppercase;
            }
        }
    }
}

.interiordesign-blog {
    .blog-author {
        background: var(--base-color) !important;
        -webkit-background-clip: text !important;
    }
}